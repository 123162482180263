import { useState, useEffect } from "react";
import Header from "../Header/Header";
import ReactPaginate from 'react-paginate';
import { axiosPrivate } from "../Api/axios";
import { faClockRotateLeft, faXmark, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../CSS/pagination.css';
import '../../CSS/cardframe.css'; 
import { MelallColorIcon, ToseeTaavonColorIcon, SepahMergedMehrEghtesadColorIcon, ResalatColorIcon, PostColorIcon, SepahMergedGhavaminColorIcon, SanatMadanColorIcon, SarmayehColorIcon, ToseeSaderatColorIcon, MaskanColorIcon, BankMarkaziColorIcon, AyandehColorIcon, EghtesadNovinColorIcon, SaderatColorIcon, SepahMergedAnsarColorIcon, PasargadColorIcon, IranZaminColorIcon, ShahrColorIcon, ParsianColorIcon, ToseeColorIcon, KarafarinColorIcon, DeyColorIcon, TejaratColorIcon, SepahMergedKosarColorIcon, GardeshgariColorIcon, SepahColorIcon, RefahColorIcon, KeshavarziColorIcon, SepahMergedHekmatColorIcon, MelliColorIcon, MehrIranColorIcon, MellatColorIcon, SamanColorIcon, SinaColorIcon } from '@snapp-store/iranian-banks-react-icons';
import cardpa from '../../img/pasagard.jpg';
import imagemellat from '../../img/melat.jpg';
import sepah from '../../img/sepah.jpg';
import noDataImage from '../../img/no-data.png';


function BankHistory() {
    const [loading, setLoading] = useState(false);
    const [numList, setNumList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const itemsPerPage = 10;
    const bankMapping = {
        "627412": { name: "بانک اقتصاد نوین", Icon: EghtesadNovinColorIcon },
        "207177": { name: "بانک توسعه صادرات ایران", Icon: ToseeSaderatColorIcon },
        "627648": { name: "بانک توسعه صادرات ایران", Icon: ToseeSaderatColorIcon },
        "627381": { name: "بانک انصار", Icon: SepahMergedAnsarColorIcon },
        "502229": { name: "بانک پاسارگاد", Icon: PasargadColorIcon },
        "639347": { name: "بانک پاسارگاد", Icon: PasargadColorIcon },
        "505785": { name: "بانک ایران زمین", Icon: IranZaminColorIcon },
        "502806": { name: "بانک شهر", Icon: ShahrColorIcon },
        "622106": { name: "بانک پارسیان", Icon: ParsianColorIcon },
        "639174": { name: "بانک پارسیان", Icon: ParsianColorIcon },
        "627884": { name: "بانک پارسیان", Icon: ParsianColorIcon },
        "502908": { name: "بانک توسعه تعاون", Icon: ToseeTaavonColorIcon },
        "628157": { name: "موسسه اعتباری توسعه", Icon: ToseeColorIcon },
        "639194": { name: "بانک کارآفرین", Icon: KarafarinColorIcon },
        "502910": { name: "بانک کارآفرین", Icon: KarafarinColorIcon },
        "502938": { name: "بانک دی", Icon: DeyColorIcon },
        "505416": { name: "بانک گردشگری", Icon: GardeshgariColorIcon },
        "627353": { name: "بانک تجارت", Icon: TejaratColorIcon },
        "505801": { name: "موسسه اعتباری کوثر", Icon: SepahMergedKosarColorIcon },
        "589210": { name: "بانک سپه", Icon: SepahColorIcon },
        "589463": { name: "بانک رفاه کارگران", Icon: RefahColorIcon },
        "603770": { name: "بانک کشاورزی", Icon: KeshavarziColorIcon },
        "639217": { name: "بانک کشاورزی", Icon: KeshavarziColorIcon },
        "636949": { name: "بانک حکمت ایرانیان", Icon: SepahMergedHekmatColorIcon },
        "603799": { name: "بانک ملی", Icon: MelliColorIcon },
        "610433": { name: "بانک ملت", Icon: MellatColorIcon },
        "991975": { name: "بانک ملت", Icon: MellatColorIcon },
        "606373": { name: "موسسه قرض الحسنه مهر ایران", Icon: MehrIranColorIcon },
        "621986": { name: "بانک سامان", Icon: SamanColorIcon },
        "639346": { name: "بانک سینا", Icon: SinaColorIcon },
        "636214": { name: "بانک آینده", Icon: AyandehColorIcon },
        "636795": { name: "بانک مرکزی", Icon: BankMarkaziColorIcon },
        "628023": { name: "بانک مسکن", Icon: MaskanColorIcon },
        "603769": { name: "بانک صادرات", Icon: SaderatColorIcon },
        "639607": { name: "بانک سرمایه", Icon: SarmayehColorIcon },
        "627961": { name: "بانک صنعت و معدن", Icon: SanatMadanColorIcon },
        "639599": { name: "بانک قوامین", Icon: SepahMergedGhavaminColorIcon },
        "639370": { name: "بانک مهر اقتصاد", Icon: SepahMergedMehrEghtesadColorIcon },
        "627760": { name: "پست بانک", Icon: PostColorIcon },
        "504172": { name: "بانک رسالت", Icon: ResalatColorIcon },
        "606256": { name: "موسسه اعتباری ملل", Icon: MelallColorIcon },
    };
    const backgroundStyles = {
        "502229": { backgroundImage: `url(${cardpa})` }, // Example image path
        "610433": { backgroundImage: `url(${imagemellat})`}, // Example color
        "991975": { backgroundImage: `url(${imagemellat})` }, // Example color
        "589210": { backgroundImage: `url(${sepah})` ,textColor: "black"}, // Example color
        "627412":  { backgroundImage: `url(${cardpa})` }, // Example color
        "207177":  { backgroundImage: `url(${cardpa})` }, // Example color
        "627648":  { backgroundImage: `url(${cardpa})` }, // Example color
        "627381":  { backgroundImage: `url(${cardpa})` }, // Example color
        "639347":  { backgroundImage: `url(${cardpa})` }, // Example color
        "505785":  { backgroundImage: `url(${cardpa})` }, // Example color
        "502806":  { backgroundImage: `url(${cardpa})` }, // Example color
        "622106":  { backgroundImage: `url(${cardpa})` }, // Example color
        "639174":  { backgroundImage: `url(${cardpa})` }, // Example color
        "627884":  { backgroundImage: `url(${cardpa})` }, // Example color
        "502908":  { backgroundImage: `url(${cardpa})` }, // Example color
        "628157": { backgroundImage: `url(${cardpa})` }, // Example color
        "639194": { backgroundImage: `url(${cardpa})` }, // Example color
        "502910":  { backgroundImage: `url(${cardpa})` }, // Example color
        "502938":  { backgroundImage: `url(${cardpa})` }, // Example color
        "505416":  { backgroundImage: `url(${cardpa})` }, // Example color
        "627353":  { backgroundImage: `url(${cardpa})` }, // Example color
        "505801":  { backgroundImage: `url(${cardpa})` }, // Example color
        
        "589463":  { backgroundImage: `url(${cardpa})` }, // Example color
        "603770":  { backgroundImage: `url(${cardpa})` }, // Example color
        "639217":  { backgroundImage: `url(${cardpa})` }, // Example color
        "636949":  { backgroundImage: `url(${cardpa})` }, // Example color
        "603799":  { backgroundImage: `url(${cardpa})` }, // Example color
       
        "621986":  { backgroundImage: `url(${cardpa})` }, // Example color
        "639346":  { backgroundImage: `url(${cardpa})` }, // Example color
        "636214":  { backgroundImage: `url(${cardpa})` }, // Example color
        "636795":  { backgroundImage: `url(${cardpa})` }, // Example color
        "628023":  { backgroundImage: `url(${cardpa})` }, // Example color
        "603769":  { backgroundImage: `url(${cardpa})` }, // Example color
        "639607":  { backgroundImage: `url(${cardpa})` }, // Example color
        "627961":  { backgroundImage: `url(${cardpa})` }, // Example color
        "639599": { backgroundImage: `url(${cardpa})` }, // Example color
        "639370":  { backgroundImage: `url(${cardpa})` }, // Example color
        "627760":  { backgroundImage: `url(${cardpa})` }, // Example color
        "504172":  { backgroundImage: `url(${cardpa})` }, // Example color
        "606256":  { backgroundImage: `url(${cardpa})` }, // Example color
        // Add more mappings as needed...
      };
     
      
    // دریافت لیست کارت‌ها از API
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchList = async () => {
            setLoading(true);
            try {
                const response = await axiosPrivate.get('/list/card', { signal: controller.signal });
                console.log("list of cards", response)
                setNumList(response?.data?.slice(0).reverse());
                
            } catch (err) {
                console.error("Error fetching card list:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchList();
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, []);

    // محاسبه تعداد صفحات
    useEffect(() => {
        setTotalPages(Math.ceil(numList.length / itemsPerPage));
    }, [numList]);
    
    // محاسبه آیتم‌های صفحه فعلی
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = numList.slice(startIndex, endIndex);

    // تغییر صفحه
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // حذف کارت
    const handleDelete = async (card) => {
        try {
            const response = await axiosPrivate.post('/remove/card', { input: card });
            if (response.status === 200) {
                setNumList((prevList) => prevList.filter(item => item.card !== card));
            }
        } catch (err) {
            console.error("Error deleting card:", err);
        }
    };
    const handleShow = async (card) => {
        try {
            const response = await axiosPrivate.post('/check/card', { input: card });
            if (response.status === 200) {
                setSelectedCard(response.data);
                setShowModal(true);
        }
    } catch (err) {
        console.error("Error showing card details:", err);
    };
    
}
const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCard(null);
};
const formatCardNumber = (number) => {
    return number.replace(/(.{4})/g, "$1 ").trim();
};

    return (
        <>
        <Header />
        <section className="dark:bg-stone-900 dark:text-gray-100 transition-all duration-100 ease-in h-screen">
            <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-x-hidden'>
                <div className="md:col-span-7 md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                <span className='inline-block place-content-center mr-4 text-green-800 dark:text-green-200 text-lg ml-2'><FontAwesomeIcon icon={faClockRotateLeft} /></span>
                    <h5 className="inline-flex items-center">تاریخچه کارت‌های بانکی</h5>
                    <div className="flex justify-end">
                        <div>
                            {numList.length > itemsPerPage && (
                                <ReactPaginate
                                    pageCount={totalPages}
                                    onPageChange={handlePageChange}
                                    forcePage={currentPage}
                                    previousLabel={"<<"}
                                    nextLabel={">>"}
                                    breakLabel={"..."}
                                    containerClassName={"pagination-container"}
                                    pageLinkClassName={"page-link"}
                                    activeLinkClassName={"active"}
                                    previousLinkClassName={"page-link"}
                                    nextLinkClassName={"page-link"}
                                    disabledClassName={"disabled"}
                                />
                            )}
                        </div>
                    </div>
                    <div className="news-card grid grid-cols-1 gap-x-4 mt-2 rounded-md px-2 inner-scroll-250 overflow-y-auto">
                       {loading ? (
                                       <p className='text-right text-lg mt-2 pr-6'>در حال بارگذاری...</p>
                                     ) : numList.length === 0 ? (
                                       <div className="flex flex-col items-center justify-center py-10">
                                         <img
                                           src={noDataImage} // تصویر "داده‌ای وجود ندارد"
                                           alt="داده‌ای وجود ندارد"
                                           className="w-32 h-32 mb-4"
                                         />
                                         <p className="text-gray-500 dark:text-gray-400 text-lg">داده‌ای برای نمایش وجود ندارد.</p>
                                       </div>
                        ) : (
                            subset.map((card, id) => {
                                const bankPrefix = card.card?.substring(0, 6); // Extract bank prefix here
                                const BankIcon = bankMapping[bankPrefix]?.Icon; // Get the bank icon
                                const cardStyle = backgroundStyles[bankPrefix]?.backgroundImage  || {backgroundImage: `url(${cardpa})`}; // Get the background styles //
                               //console.log(cardStyle);
                               // console.log('test 1', backgroundStyles[bankPrefix].backgroundImage, 'test2  :',bankMapping[bankPrefix]); //
                               // const cardStyle = backgroundStyles[bankPrefix] || {backgroundImage: `url(${sepah})` }; // Shared cardStyle
                
                                return (
                                <div key={id} className="w-full bg-gray-50 dark:bg-gray-600 hover:bg-blue-200 transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-3">
                                    <div className="news-card-header col-span-3">
                                        <div className="flex justify-between items-center">
                                    <div className="flex flex-col items-start">
                                        <p className="text-sm text-gray-600 dark:text-white">شماره کارت: {card.card}</p>
                                        <p className="text-sm text-gray-600 dark:text-white">تاریخ ثبت: {card.created_at}</p>
                                        <p className="text-sm text-gray-600 dark:text-white">آخرین آپدیت: {card.updated_at}</p>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-sm text-gray-600 dark:text-white">نام صاحب حساب: {card.name}</p>
                                        <p className="text-sm text-gray-600 dark:text-white">نام بانک: {card.bankName}</p>
                                        {/* Conditional rendering for the status messages */}
                                        {card.status === 3 && <span className="text-sm text-green-600">استعلام موفق</span>}
                                        {card.status === 1 && <span className="text-sm text-red-600">شماره کارت در سیستم بانکی وجود ندارد</span>}
                                        {card.status === 2 && <span className="text-sm text-blue-600">در حال استخراج اطلاعات</span>}
                                    </div>
                                         
                                        <div className="mt-2">
                                            {card.card && (() => {
                                                
                                                return BankIcon ? <BankIcon height={50} className="bank-icon mb-5"/> : null; // Correct JSX syntax
                                                // Adjust height as needed
                                            })()}
                                        </div>
                                        <div className="flex items-center gap-x-1 space-x-2">
                                       {card.status === 3 && ( <button onClick={() => {
                                          setSelectedCard(card);
                                            setShowModal(true);
                                            }} 
                                            className="py-1 px-2 bg-green-500 text-white rounded hover:bg-blue-700 transition-all duration-200 ease-in mr-50">
                                        <span >مشاهده کارت</span>
                                        </button>)}
                                        {showModal && selectedCard && (
                                            <div className="fixed inset-0 flex items-center justify-center z-50 bg-white bg-opacity-100 rounded-lg shadow-lg">
                                              <div className=" p-4 w-1/2">
                                              <div className="container">
                                              <div className="card bg-cover bg-center h-screen border-radius " style={{
                                                    backgroundImage: backgroundStyles[selectedCard.card?.substring(0, 6)]?.backgroundImage || `url(${cardpa})`}}>
                                                    <div className="">
                                                        <div className="front">
                                                            <div className="row justify-between">
                                                            {selectedCard.card && (
                                                                    (() => {
                                                                        const BankIcon =
                                                                            bankMapping[selectedCard.card.substring(0, 6)]?.Icon;

                                                                        return BankIcon ? <BankIcon height={80}    className="bank-icon"  /> : null;
                                                                    })()
                                                                )}
                                                            </div>
                                                            <div className="row name text-white mt-[-10]">
                                                                <p>{selectedCard.bankName}</p>
                                                            </div>
                                                            <div className="row card-no" style={{ color: backgroundStyles[selectedCard.card?.substring(0, 6)]?.textColor || "white"  }}>
                                                                <div style={{ }}>
                                                                <p>{formatCardNumber(selectedCard.card)}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row card-holder" style={{ color: backgroundStyles[selectedCard.card?.substring(0, 6)]?.textColor || "white" }}>
                                                                <p>نام و نام خانوادگی  :{selectedCard.name}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                    
                                                             <div className="flex justify-center">            
                                                                        <button
                                                                            onClick={() => {handleCloseModal()}}
                                                                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-5" >
                                                                            انصراف</button>
                                                                        </div>
                                                                    
                                                                         
                                                                        </div>
                                                                        </div> )}
                                        <button
                                            className="py-1 px-2 bg-red-500 text-white rounded hover:bg-red-700 transition-all duration-200 ease-in"
                                            onClick={() => handleDelete(card.card)}
                                        >
                                            حذف
                                        </button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            );
                        })
                    )}
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default BankHistory;
