import { useState, useEffect } from "react";
import Header from "../Header/Header";
import ReactPaginate from 'react-paginate';
import { faIdCard, faTrash, faDatabase } from "@fortawesome/free-solid-svg-icons"; // آیکون جدید
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import kartmeli from '../../img/idcard.jpg'; // تصویر کارت ملی
import noDataImage from '../../img/no-data.png'; // تصویر "داده‌ای وجود ندارد"

function NationalIdHistory() {
  const [loading, setLoading] = useState(false);
  const [historyList, setHistoryList] = useState([]); // لیست تاریخچه استعلام‌ها
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // آیتم انتخاب شده برای نمایش در مودال
  const itemsPerPage = 10;

  // شبیه‌سازی دریافت تاریخچه استعلام‌ها
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setHistoryList([]); // لیست خالی برای شبیه‌سازی عدم وجود داده
      setLoading(false);
    }, 1000);
  }, []);

  // محاسبه تعداد صفحات
  useEffect(() => {
    setTotalPages(Math.ceil(historyList.length / itemsPerPage));
  }, [historyList]);

  // محاسبه آیتم‌های صفحه فعلی
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const subset = historyList.slice(startIndex, endIndex);

  // تغییر صفحه
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // حذف استعلام
  const handleDelete = async (nationalId) => {
    try {
      // شبیه‌سازی حذف استعلام
      setHistoryList((prevList) => prevList.filter(item => item.nationalId !== nationalId));
    } catch (err) {
      console.error("خطا در حذف استعلام:", err);
    }
  };

  // بستن مودال
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  return (
    <>
      <Header />
      <section className="dark:bg-stone-900 dark:text-gray-100 transition-all duration-100 ease-in h-screen">
        <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-x-hidden'>
          <div className="md:col-span-7 md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
            <span className='inline-block place-content-center mr-4 text-green-800 dark:text-green-200 text-lg ml-2'>
              <FontAwesomeIcon icon={faIdCard} /> {/* آیکون کارت ملی */}
            </span>
            <h5 className="inline-flex items-center">تاریخچه استعلام‌های کارت ملی</h5>

            {/* پینگ‌یشن */}
            <div className="flex justify-end">
              {historyList.length > itemsPerPage && (
                <ReactPaginate
                  pageCount={totalPages}
                  onPageChange={handlePageChange}
                  forcePage={currentPage}
                  previousLabel={"<<"}
                  nextLabel={">>"}
                  breakLabel={"..."}
                  containerClassName={"pagination-container"}
                  pageLinkClassName={"page-link"}
                  activeLinkClassName={"active"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  disabledClassName={"disabled"}
                />
              )}
            </div>

            {/* لیست تاریخچه استعلام‌ها */}
            <div className="news-card grid grid-cols-1 gap-x-4 mt-2 rounded-md px-2 inner-scroll-250 overflow-y-auto">
              {loading ? (
                <p className='text-right text-lg mt-2 pr-6'>در حال بارگذاری...</p>
              ) : historyList.length === 0 ? (
                <div className="flex flex-col items-center justify-center py-10">
                  <img
                    src={noDataImage} // تصویر "داده‌ای وجود ندارد"
                    alt="داده‌ای وجود ندارد"
                    className="w-32 h-32 mb-4"
                  />
                  <p className="text-gray-500 dark:text-gray-400 text-lg">داده‌ای برای نمایش وجود ندارد.</p>
                </div>
              ) : (
                subset.map((item, id) => (
                  <div key={id} className="w-full bg-gray-50 dark:bg-gray-600 hover:bg-blue-200 transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-3">
                    <div className="news-card-header col-span-3">
                      <div className="flex justify-between items-center">
                        <div className="flex flex-col items-start">
                          <p className="text-sm text-gray-600 dark:text-white">کد ملی: {item.nationalId}</p>
                          <p className="text-sm text-gray-600 dark:text-white">نام کامل: {item.fullName}</p>
                          <p className="text-sm text-gray-600 dark:text-white">تاریخ استعلام: {item.created_at}</p>
                        </div>
                        <div className="flex flex-col">
                          <p className="text-sm text-gray-600 dark:text-white">نام پدر: {item.fatherName}</p>
                          <p className="text-sm text-gray-600 dark:text-white">تاریخ تولد: {item.birthDate}</p>
                          <p className="text-sm text-gray-600 dark:text-white">وضعیت: {item.isValid ? 'معتبر ✓' : 'نامعتبر ✗'}</p>
                        </div>
                        <div className="flex items-center gap-x-1 space-x-2">
                          <button
                            onClick={() => {
                              setSelectedItem(item);
                              setShowModal(true);
                            }}
                            className="py-1 px-2 bg-green-500 text-white rounded hover:bg-blue-700 transition-all duration-200 ease-in mr-50"
                          >
                            مشاهده جزئیات
                          </button>
                          <button
                            className="py-1 px-2 bg-red-500 text-white rounded hover:bg-red-700 transition-all duration-200 ease-in"
                            onClick={() => handleDelete(item.nationalId)}
                          >
                            حذف
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </section>

      {/* مودال نمایش جزئیات */}
      {showModal && selectedItem && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-white bg-opacity-100">
          <div className="p-4 w-1/2">
            <div className="container">
              <div className="card bg-cover bg-center h-screen border-radius" style={{ backgroundImage: `url(${kartmeli})` }}>
                <div className="front">
                  <div className="row justify-between"></div>
                  <div className="row name text-white mt-[-10]">
                    <p>کد ملی: {selectedItem.nationalId}</p>
                  </div>
                  <div className="row card-no text-white">
                    <p>نام کامل: {selectedItem.fullName}</p>
                  </div>
                  <div className="row card-holder boxdark text-white">
                    <p>نام پدر: {selectedItem.fatherName}</p>
                  </div>
                  <div className="row card-holder boxdark text-white">
                    <p>تاریخ تولد: {selectedItem.birthDate}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <button
                onClick={() => handleCloseModal()}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-5"
              >
                انصراف
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NationalIdHistory;