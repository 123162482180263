import { useState } from 'react';
import Header from "../Header/Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard } from "@fortawesome/free-solid-svg-icons";
import kartmeli from '../../img/idcard.jpg';
import image2 from '../../img/account-9d64c1a4.svg';
function Caridchecker() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [nationalIdInfo, setNationalIdInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);
  // اعتبارسنجی شماره تلفن
  const validatePhoneNumber = (phone) => {
    if (!/^\d{11}$/.test(phone)) return 'شماره تلفن باید 11 رقم باشد';
    return null;
  };

  // داده‌های دمو (mock data)
  const mockData = {
    "09123456789": {
      nationalId: "1234567890",
      firstName: "علی",
      LastName: "محمدی",
      fatherName: "احمد",
      birthDate: "1370/05/15",
      province: "تهران",
      city: "تهران",
      isValid: true,
      imageUrl: image2,
    },
    "09111111111": {
      nationalId: "9876543210",
      fullName: "فاطمه زهرا",
      fatherName: "محمد",
      birthDate: "1365/12/25",
      province: "اصفهان",
      city: "اصفهان",
      isValid: true
    }
  };

  // ارسال درخواست به بک‌اند (یا استفاده از mock data)
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validatePhoneNumber(phoneNumber);
    setImage(kartmeli);
    if (validationError) {
      setError(validationError);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // شبیه‌سازی فراخوانی API با استفاده از mock data
      const data = mockData[phoneNumber];
      if (!data) throw new Error('شماره تلفن یافت نشد');

      // شبیه‌سازی تاخیر برای واقعی‌تر شدن
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setNationalIdInfo(data);
    } catch (err) {
      setError(err.message || 'خطا در دریافت اطلاعات');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <section className="dark:bg-stone-900 dark:text-gray-100 transition-all duration-100 ease-in h-screen overflow-auto">
        <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52  overflow-x-hidden '>
          <div className="md:col-span-7 md:p-4 rounded-md dark:bg-stone-900 ">
            <span className='inline-block place-content-center mr-4 text-green-800 dark:text-green-200 text-lg ml-2'>
              <FontAwesomeIcon icon={faIdCard} />
            </span>
            <h5 className="inline-flex items-center">استعلام کارت ملی</h5>
            <h4 className="flex items-center justify-center">لطفا شماره تلفن را وارد کنید.</h4>
            <form onSubmit={handleSubmit} className="flex justify-center mt-3">
              <div className="form-group">
                <label>شماره تلفن:</label>
                
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  maxLength="11"
                  placeholder="نمونه ورودی : 09123456789"
                   className='border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-2 w-72 dark:bg-gray-700 dark:text-white'
                />
              </div>

              <button type="submit" disabled={loading} className={`py-2 px-4 h-10 subBut text-white hover:bg-yellow bg-light-green rounded mr-2 cursor-pointer items-center`}>
                {loading ? 'در حال بررسی...' : 'جستجو'}
              </button>

              {error && <div className="error text-red-500 mt-2">{error}</div>}
            </form>
            <hr/>
            <div className="flex items-center justify-center mt-3 dark:bg-stone-900">
            
            
            {nationalIdInfo && (
            <div className='flex items-center justify-center mt-4 relative'>
              {/* عکس */}
              <img 
                src={image} 
                alt=" عکس کارت ملی" 
                style={{ 
                  width: '500px', 
                  height: 'auto', 
                  border: '2px solid #000', 
                  borderRadius: '5px', 
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' 
                }} 
              />

              {/* متن‌ها داخل یک div با پس‌زمینه شفاف */}
              <div 
                style={{
                  position: 'absolute',
                  top: '1.2cm', // فاصله از بالای عکس
                  left: '8cm', // فاصله از چپ عکس
                 // backgroundColor: 'rgba(255, 255, 255, 0.8)', // پس‌زمینه شفاف
                  padding: '10px', // فاصله داخلی
                  borderRadius: '5px', // گردی گوشه‌ها
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // سایه
                  maxWidth: '80%', // حداکثر عرض
                }}
              >
                <p style={{ marginBottom: '7px' }}>  {nationalIdInfo.nationalId || 'نامعلوم'}</p>
                <p style={{ marginBottom: '7px' }}> {nationalIdInfo.firstName || 'نامعلوم'}</p>
                <p style={{ marginBottom: '7px' }}> {nationalIdInfo.LastName || 'نامعلوم'}</p>
                <p style={{ marginBottom: '7px' }}> {nationalIdInfo.birthDate || 'نامعلوم'}</p>
                <p style={{ marginBottom: '0px' }}> {nationalIdInfo.fatherName || 'نامعلوم'}</p>
                {/* <p style={{ marginBottom: '10px' }}> {nationalIdInfo.province || 'نامعلوم'}</p>
                <p style={{ marginBottom: '10px' }}>شهر: {nationalIdInfo.city || 'نامعلوم'}</p> */}
                <p style={{ marginBottom: '0px' }}> {nationalIdInfo.isValid ? 'معتبر ✓' : 'نامعتبر ✗'}</p>
              </div>
              <div 
                className='relative black-image-container' // اضافه کردن position: relative به والد
                style={{
                  position: 'absolute',
                  top: '1.5cm', // فاصله از بالای عکس
                  right: '8.8cm', // فاصله از چپ عکس
                  //backgroundColor: 'rgba(255, 255, 255, 0.8)', // پس‌زمینه شفاف
                  padding: '00px', // فاصله داخلی
                  borderRadius: '0px', // گردی گوشه‌ها
                  maxWidth: '100%', // حداکثر عرض
                  filter: 'grayscale(100%) brightness(0)',
                }}
              >
                <img
                  className="w-full h-full object-contain object-center " // تصویر به اندازه والد بزرگ می‌شود
                  src={nationalIdInfo.imageUrl}
                  alt={'Photo of ' + nationalIdInfo.LastName}
                  style={{
                    maxWidth: '80%', // تصویر حداکثر به اندازه والد بزرگ می‌شود
                    maxHeight: '50%', // تصویر حداکثر به اندازه والد بزرگ می‌شود
                  }}
                />
              </div>
             
            </div>
          )}
            </div>
          </div>
        </div>
      </section>
    </>
  );

}export default Caridchecker;