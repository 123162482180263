import { useState, useEffect } from "react";
import Header from "../Header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft, faTrash } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import { axiosPrivate } from "../Api/axios";
import "../../CSS/pagination.css";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { useForm, Controller } from "react-hook-form";
import moment from 'moment-jalaali';
import DatePicker from 'react-multi-date-picker';
import noDataImage from '../../img/no-data.png';

function CallerHistory() {
  const [loading, setLoading] = useState(false);
  const [numList, setNumList] = useState([]);
  const [showModal, setShowModal] = useState(false);  // State for modal visibility
  const [inputValue, setInputValue] = useState('');   // State for user input
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const { control, handleSubmit, formState: { errors } } = useForm();
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axiosPrivate.get(`/list/mobile`);
        setNumList(response?.data?.slice(0).reverse());
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const totalPages = Math.ceil(numList?.length / itemsPerPage);
  const subset = numList?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleDelete = async (mobile) => {
    try {
      const response = await axiosPrivate.post("/remove/mobile", { input: mobile });
      if (response.status === 200) {
        setNumList((prevList) => prevList.filter((item) => item.mobile !== mobile));
      }
    } catch (err) {
      console.error("Error deleting item:", err);
    }
  };
  //دانلود تمام اطلاعات
      const handleExportAll = async () => {
          try {
              const formData = new FormData();
              formData.append("all", true);
              const exportResponse  = await  axiosPrivate.post(`/export/caller`, formData, {
              });
              console.log("Export Response:", exportResponse .data);
              const fileUrl = exportResponse?.data?.file;
              if (fileUrl) {
                  const link = document.createElement('a');
                  link.href = fileUrl;
                  link.setAttribute('download', 'instagram_records.xlsx');
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
              } else {
                  console.error("File URL not found in export response.");
                  alert("No records found for the export request.");
              }
          } catch (error) {
              console.error("Export Error:", error);
              alert("An error occurred while exporting data. Please try again.");
          }
          finally {
              setShowModal(false);
          }
      };
   
      const handleDownload = async () => {
          try {
              setLoading(true);
               if (!inputValue && (!dateStart || !dateEnd)) {
                  alert(".لطفا یک فیلد را تکمیل کنید");
                  setLoading(false);
                  return;
              }
               const startDate = dateStart ? moment(dateStart.toDate()).format('YYYY/MM/DD') : null;
               const endDate = dateEnd ? moment(dateEnd.toDate()).format('YYYY/MM/DD') : null; 
              
              const requestBody = {
                  count: inputValue && !isNaN(Number(inputValue)) ? Number(inputValue) : null,
                  date_start: startDate,
                  date_end: endDate
              };
              console.log("Request Body:", requestBody);
              const response = await axiosPrivate.post(`/export/caller`, requestBody);
              console.log("Backend Response:", response.data);
              const fileUrl = response?.data?.file;
              if (fileUrl) {
                  const link = document.createElement('a');
                  link.href = fileUrl;
                  link.setAttribute('download', 'record.xlsx'); 
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
              } else {
                  console.error("File URL not found in response.");
              }
          } catch (err) {
              console.error("Error:", err);
          }
         
          finally {
              setLoading(false);
              setShowModal(false);
          }
      };

  return (
    <>
      <Header />
      <section className="dark:bg-stone-900 dark:text-gray-100 transition-all duration-100 ease-in h-screen">
        <div className="grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-x-hidden">
          <div className="md:col-span-7 md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
            <span className='inline-block place-content-center mr-4 text-green-800 dark:text-green-200 text-lg ml-2'><FontAwesomeIcon icon={faClockRotateLeft} /></span>
            <h5 className="inline-flex items-center">تاریخچه استعلام</h5>
            <div className="flex justify-end">
                                <div>
                                    {/* <button
                                        onClick={() => setShowModal(true)}
                                        className="py-1 px-2 hover:bg-yellow text-white text-base mt-2.5 mr-1 bg-green-500 rounded select-none transition-all duration-200 ease-in mb-2.5"
                                    >
                                        دانلود خروجی
                                    </button> */}
                                    {showModal && (<div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
                                                        <div className="bg-white p-4 rounded w-96 shadow-lg">
                                                            <form onSubmit={handleSubmit(handleDownload)} >
                                                                <h2 className="text-lg font-bold mb-4">چند رکورد آخر دانلود شود؟</h2>
                                                                    <input
                                                                            type="number"
                                                                            value={inputValue}
                                                                            id='num'
                                                                            onChange={(e) => setInputValue(e.target.value)}
                                                                            className="border border-gray-300 p-2 rounded mb-4 w-full"
                                                                            placeholder="تعداد رکورد"
                                                                    />
                                                                    <span className="block mb-1">تاریخ شروع:</span>
                                                                    <Controller
                                                                            control={control}
                                                                            name="dateStart"
                                                                            render={({ field }) => (
                                                                                <DatePicker
                                                                                placeholder="تاریخ شروع را انتخاب کنید"
                                                                                value={dateStart}
                                                                                id='date1'
                                                                                onChange={(date) => {
                                                                                        setDateStart(date);
                                                                                        field.onChange(date);
                                                                                }}
                                                                                format={"YYYY/MM/DD"}
                                                                                calendar={persian}
                                                                                locale={persian_fa}
                                                                                calendarPosition="bottom-right"
                                                                                />
                                                                            )}
                                                                            />
                                                                    <span className="block mb-1">تاریخ پایان:</span>
                                                                            <Controller
                                                                                control={control}
                                                                                name="dateEnd"
                                                                                render={({ field }) => (
                                                                                        <DatePicker
                                                                                        id='date2'
                                                                                        placeholder="تاریخ پایان را انتخاب کنید"
                                                                                        value={dateEnd}
                                                                                        onChange={(date) => {
                                                                                            setDateEnd(date);
                                                                                            field.onChange(date);
                                                                                        }}
                                                                                        format={"YYYY/MM/DD"}
                                                                                        calendar={persian}
                                                                                        locale={persian_fa}
                                                                                        calendarPosition="bottom-left"
                                                                                        />
                                                                                )}
                                                                            />
                                                                        <div className="flex justify-center">            
                                                                        <button type="submit" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-2">دانلود </button>
                                                                        <button
                                                                            onClick={() => setShowModal(false)}
                                                                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" >
                                                                            انصراف</button>
                                                                        </div>
                                                                    </form>
                                                                         <div className="flex justify-center">
                                                                        <button onClick={handleExportAll} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-1">
                                                                                دانلود تمام اطلاعات
                                                                        </button>
                                                                        </div>
                                                                        </div>
                                                                        </div> )}
                                </div>
                                <div className='pl-8 flex justify-end'>
                                    {
                                        !loading && totalPages > 0 && 
                                        <div className="pagination-container">
                                            <ReactPaginate
                                                pageCount={totalPages}
                                                onPageChange={handlePageChange}
                                                forcePage={Math.min(currentPage, totalPages - 1)}
                                                previousLabel={"<<"}
                                                nextLabel={">>"}
                                                breakLabel={"..."}
                                                pageRangeDisplayed={1}
                                                marginPagesDisplayed={1}
                                                containerClassName={"pagination-container"}
                                                pageLinkClassName={"page-link"}
                                                activeLinkClassName={"active"}
                                                previousLinkClassName={"page-link"}
                                                nextLinkClassName={"page-link"}
                                                disabledClassName={"disabled"}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
            <div className="news-card grid grid-cols-1 gap-x-4 mt-2 rounded-md px-2 inner-scroll-250 overflow-y-auto">
              {loading ? (
                                                     <p className='text-right text-lg mt-2 pr-6'>در حال بارگذاری...</p>
                                                   ) : numList.length === 0 ? (
                                                     <div className="flex flex-col items-center justify-center py-10">
                                                       <img
                                                         src={noDataImage} // تصویر "داده‌ای وجود ندارد"
                                                         alt="داده‌ای وجود ندارد"
                                                         className="w-32 h-32 mb-4"
                                                       />
                                                       <p className="text-gray-500 dark:text-gray-400 text-lg">داده‌ای برای نمایش وجود ندارد.</p>
                                                     </div>
              ) : (
                subset.map((log, i) => (
                  <div
                    key={i}
                    className="flex justify-between items-center bg-gray-50 dark:bg-gray-600 hover:bg-blue-200 transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-4"
                  >
                   <div>
                      <p>شماره تماس: {log.mobile}</p>
                      <p>نام کاربر: {log.caller?.[0]?.name?.[0]?.name || "ناشناس"}</p>
                      <p>
                        وضعیت استعلام:{" "}
                        <span
                          className={`${
                            log.caller_status === 1
                              ? "text-green-500" // وضعیت معتبر - سبز
                              : log.caller_status === 2
                              ? "text-red-500" // وضعیت نامعتبر - قرمز
                              : "text-blue-500" // وضعیت ناشناس - آبی
                          }`}
                        >
                          {log.caller_status === 1
                            ? "شماره یافت شد"
                            : log.caller_status === 2
                            ? "شماره یافت نشد"
                            : "در حال استخراج اطلاعات"}
                        </span>
                      </p>
                    </div>
                    <button
                      className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 transition"
                      onClick={() => handleDelete(log.mobile)}
                    >
                      حذف
                    </button>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CallerHistory;
