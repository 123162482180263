import { faCar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Header from "../Header/Header";
import pelak from '../../img/pelak.png';

const Caridchecker = () => {
    const [nationalId, setNationalId] = useState('');
    const [plateImage, setPlateImage] = useState(null);
    const [violations, setViolations] = useState([]);

    const handleNationalIdChange = (e) => {
        setNationalId(e.target.value);
    };

    const handleSearch = () => {
        // نمونه تصویر پلاک خودرو
        setPlateImage(pelak);
        
        // تأخیر یک ثانیه‌ای قبل از نمایش اطلاعات خلافی
        setTimeout(() => {
            setViolations([
                { date: '1402/01/01', type: 'سرعت غیرمجاز', amount: 200000 },
                { date: '1402/02/15', type: 'پارک غیرمجاز', amount: 100000 },
                { date: '1402/03/10', type: 'کمربند ایمنی', amount: 50000 },
                // اضافه کردن داده‌های بیشتر برای آزمایش اسکرول
                { date: '1402/04/01', type: 'حرکت در شلوغی', amount: 300000 },
                { date: '1402/05/15', type: 'عدم رعایت حق تقدم', amount: 150000 },
                { date: '1402/06/10', type: 'دور زدن غیرمجاز', amount: 250000 },
                { date: '1402/07/20', type: 'عدم رعایت سرعت', amount: 180000 },
                { date: '1402/08/25', type: 'عدم استفاده از چراغ راهنما', amount: 70000 },
            ]);
        }, 1000);
    };

    const plateData = {
        number: "32",
        letter: "ل",
        suffix: "444",
        countryCode: "22",
    };
    const convertToArabicNumbers = (num) => {
        const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
        return String(num).replace(/\d/g, (d) => arabicDigits[d]);
    };
    return (
        <>
            <Header />
            <section className="dark:bg-stone-900 dark:text-gray-100 transition-all duration-100 ease-in h-screen overflow-y-auto ">
            
                <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-y-auto'>
                    <div className="md:col-span-7 md:p-4 rounded-md ">
                        <span className='inline-block place-content-center mr-4 text-green-800 dark:text-green-200 text-lg ml-2'>
                            <FontAwesomeIcon icon={faCar} />
                        </span>
                        <h5 className="inline-flex items-center ">استعلام پلاک خودرو </h5>
                        <h4 className="flex items-center justify-center">لطفا کد ملی را وارد کنید.</h4>
                        <div>
                            <div className="flex items-center justify-center mt-4">
                                <input
                                    type="text"
                                    value={nationalId}
                                    onChange={handleNationalIdChange}
                                    placeholder="0023315498"
                                    className='border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-2 w-72 dark:bg-gray-700 dark:text-white'
                                />
                                <button onClick={handleSearch} className='py-2 px-4 h-10 subBut text-white hover:bg-yellow bg-light-green rounded mr-2 cursor-pointer items-center'>
                                    جستجو
                                </button>
                            </div>
                            <hr></hr>
                            {plateImage && (
                                <div className='flex items-center justify-center mt-4 relative dark:text-black'>
                                    <img src={plateImage} alt="پلاک خودرو" style={{ width: '400px', height: 'auto' }} />
                                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                                        <span style={{ 
                                             fontSize: '2.5vw', 
                                             direction: 'ltr', // Keep direction left-to-right
                                             textAlign: 'center',
                                             fontWeight: 'bold',
                                             marginLeft: '8.5cm',
                                             marginTop: '20px',
                                             padding: '20px 5px', // Custom padding for countryCode
                                             borderRadius: '5px', // Optional border radius
                                             position: 'absolute',
                                        }}>
                                            {convertToArabicNumbers(plateData.countryCode)}
                                        </span>
                                        <span style={{ 
                                              fontSize: '2.5vw', // Adjust based on image size
                                              direction: 'ltr', // Keep direction left-to-right
                                              textAlign: 'center',
                                              fontWeight: 'bold',
                                              padding: '10px 5px', // Custom padding for countryCode
                                              borderRadius: '5px', // Optional border radius
                                              
                                              marginRight: '1.5cm',
                                        }}>
                                           {convertToArabicNumbers(plateData.suffix)}
                                        </span>
                                        <span style={{ 
                                             fontSize: '2.5vw', // Adjust based on image size
                                             direction: 'ltr', // Keep direction left-to-right
                                             textAlign: 'center',
                                             fontWeight: 'bold',
                                             padding: '10px 5px', // Custom padding for countryCode
                                             borderRadius: '5px', // Optional border radius
                                             position: 'absolute',
                                             marginRight: '1.5cm',
                                        }}>
                                            {`${plateData.letter}`}
                                        </span>

                                            {/* {`${convertToArabicNumbers(plateNumber.split(' ')[0])} ل ${convertToArabicNumbers(plateNumber.split(' ')[1])} ${convertToArabicNumbers(plateNumber.split(' ')[2])}`} */}
                                     <span style={{
                                           fontSize: '2.5vw', // Smaller size for number
                                           direction: 'ltr', // Maintain left-to-right for numbers
                                           textAlign: 'right', // Align to the right
                                           fontWeight: 'bold',
                                           marginTop: '5px', // Margin for separation
                                           marginRight: '110px', // Increased margin for separation
                                           padding: '5px', // Custom padding for number
                                           borderRadius: '5px', // Optional rounding
                                           position: 'relative',
                                    }}>
                                        {convertToArabicNumbers(plateData.number)}
                                    </span>
                                    </div>
                                </div>
                            )}

                            {violations.length > 0 && (
                                <div className="mt-4 mr-4 overflow-y-auto h-68 shadow-lg rounded-lg border border-gray-200 dark:text-gray-100 dark:bg-gray-900">
                                    <h2 className='flex items-center justify-center text-lg p-2 bg-light-green text-white dark:bg-gray-700'>جدول خلافی‌ها:</h2>
                                    <div className="overflow-x-auto">
                                        <table className="min-w-full border-collapse ">
                                            <thead>
                                                <tr className="bg-light-green text-white dark:bg-gray-700 text-center">
                                                    <th className="border border-gray-300 p-4 dark:text-gray-200">تاریخ</th>
                                                    <th className="border border-gray-300 p-4  dark:text-gray-200">نوع خلافی</th>
                                                    <th className="border border-gray-300 p-4 dark:text-gray-200">مقدار (تومان)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {violations.map((violation, index) => (
                                                    <tr key={index} className="hover:bg-gray-100 dark:hover:bg-gray-600 text-center">
                                                        <td className="border border-gray-300 p-4">{violation.date}</td>
                                                        <td className="border border-gray-300 p-4">{violation.type}</td>
                                                        <td className="border border-gray-300 p-4">{violation.amount.toLocaleString()}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Caridchecker;