import React from 'react';
import { Routes, Route } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import '../CSS/index.css';
import Home from './Home';
import Layout from './Layout';
import LoginPage from '../Components/login page/SignIn';
import AccInq from './AccInq';
import NumInq from './NumInq';
import NumSubmit from './NumSubmit';
import Register from '../Components/login page/SignUp';
import Missing from './Missing';
import RequireAuth from './RequireAuth';
import RequireNotAuth from './RequireNotAuth';
import Profile from '../Components/profile page/Profile';
import AccDet from '../Components/instagram page/AccDet';
import InpHistory from '../Components/number page/InpHistory';
import TwDet from '../Components/twitter page/TwDet';
import TelDet from '../Components/telegram page/TelDet';
import InstaPost from '../Components/instagram page/InstaPost';
import FollowHistory from '../Components/instagram page/FollowHistory';
import WhatsappDet from '../Components/Whatsapp page/WhatsappDet';
import InstaInq from '../Components/instagram page/InstaInq';
import InstaHistory from '../Components/instagram page/InstaHistory';
import WhatsappHistory from '../Components/Whatsapp page/WhatsappHistory';
import UsersList from '../Components/users page/UsersList';
import CreateUserButton from '../Components/user register/formdata';
import MainComponent from '../Components/user register/MainComponent';
import BankCardChecker from '../Components/bank page/Bankcard';
import BankHistory from '../Components/bank page/BankHistory';
import TruecallerDet from './Truecaller-page/truecallerdet';
import CallerHistory from './Truecaller-page/callerHistory';
import CarIdChecker from './Car-id/CarInp';
import CarPlateHistory from './Car-id/CarHistory';

import IdCard from './card-id/Cardinp';
import NationalIdHistory from './card-id/Card-History';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Routes for unauthenticated users */}
        <Route element={<RequireNotAuth />}>
          <Route path="sign-in" element={<LoginPage />} />
          <Route path="sign-up" element={<Register />} />
        </Route>

        {/* Routes for authenticated users */}
        <Route element={<RequireAuth />}>
          <Route index element={<Home />} />
          <Route path="social-inquiry" element={<AccInq />} />
          <Route path="number-inquiry" element={<NumInq />} />
          <Route path="insta-inquiry" element={<InstaInq />} />
          <Route path="number-submit" element={<NumSubmit />} />
          <Route path="profile" element={<Profile />} />
          <Route path="account-details" element={<AccDet />} />
          <Route path="input-history" element={<InpHistory />} />
          <Route path="insta-history" element={<InstaHistory />} />
          <Route path="telegram-details" element={<TelDet />} />
          <Route path="whatsapp-details" element={<WhatsappDet />} />
          <Route path="twitter-details" element={<TwDet />} />
          <Route path="instagram-posts" element={<InstaPost />} />
          <Route path="instagram-history" element={<FollowHistory />} />
          <Route path="whatsapp-history" element={<WhatsappHistory />} />
          <Route path="users-list" element={<UsersList />} />
          <Route path='formdata' element={<CreateUserButton />} />
          <Route path='maincomponent' element={<MainComponent />} />
          <Route path='Bankcard' element={<BankCardChecker />} />
          <Route path='BankHistory' element={<BankHistory />} />
          <Route path='truecaller' element={<TruecallerDet/>} />
          <Route path="Caller-history" element={<CallerHistory />} />

          <Route path="Carid" element={<CarIdChecker />} />
          <Route path="Carid-history" element={<CarPlateHistory />} />
          <Route path="IDcard" element={<IdCard />} />
          <Route path="IDCardhistory" element={<NationalIdHistory />} />
        </Route>

        {/* Catch-all route for undefined paths */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;

