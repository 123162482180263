import { useContext, useState, useEffect, useRef } from "react";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { axiosPrivate } from "../Api/axios";
import UIContext from '../Context/UiContext';
import { faClipboardQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BulkInp from "../number page/BulkInp";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
function InpInqtruecaller() {
    const { 
        setInq,
        setDetShow,
        setSocailCh,
        setShow,
        setTableShow,
        subAlert,
        setSubAlert,
        setInqShow,
        inqData,
        setInqData,
        setAccData } = useContext(UIContext);

    const [input, setInput] = useState(null);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);
    const [inputKey, setInputKey] = useState(Date.now());
    const [phoneData, setPhoneData] = useState({
        phone: '',
        country: 'IR',
        countryCode: '+98',
    });

    const iranPhonePattern = /^(\+98|98)?9\d{9}$/;


    const handlePhoneChange = (value) => {
        setPhoneData((prevState) => ({
            ...prevState,
            phone: value.startsWith('+') ? value : `+${value}`,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let phoneNumber;
               const inputValue = phoneData.phone;
               if (inputValue.startsWith('+')) {
                   phoneNumber = parsePhoneNumberFromString(inputValue);
                 } else {
                   // اگر شماره بدون + باشد، از کد کشور انتخاب شده استفاده می‌کنیم.
                   // فرض بر این است که phoneData.countryCode به صورت کد ISO (مثلاً "IR", "US", ...) است.
                   if (phoneData.countryCode) {
                     phoneNumber = parsePhoneNumberFromString(inputValue, phoneData.countryCode.toUpperCase());
                   } else {
                     alert("لطفاً کشور خود را انتخاب کنید یا شماره را با کد بین‌المللی وارد کنید.");
                     return;
                   }
                 }
                 if (!phoneNumber || !phoneNumber.isValid()) {
                   alert("شماره تلفن وارد شده صحیح نمی‌باشد.");
                   return;
                 }
                 const cleanPhone = phoneNumber.format("E.164").substring(1);
                   console.log("Cleaned Phone Number:", cleanPhone);
       
        setShow(false);
        setTableShow(true);
        setInput(cleanPhone);
        setInputKey(Date.now()); // This changes on every submission
        setSubAlert(true);
        setInqShow(true);
        // Clear the input after submission

        setPhoneData({
            phone: '',
            country: '',
            countryCode: ''
        });
        inputRef.current.value = '';
    };

    const dontHandle = () => {
        alert("لطفا ورودی را مشخص کنید")
    };
    
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getList = async () => {
            try {
                setLoading(true);
                const response = await axiosPrivate.get(`/check/mobile/${input}`, {
                    signal: controller.signal
                });
                console.log(response);
                response?.data?.code == 403 && setLoading(false);
                if (isMounted) {
                    setInqData(response?.data);
                    console.log(response?.data);
                    if (response?.data?.code === "200") {
                        setInq(response?.data?.data[0]);
                        console.log(response?.data?.data[0]);
                        //my mix
                        setSocailCh("caller");
                        //? 
                        setAccData(response.data?.data[0]);
                        // 
                        setDetShow(false);
                        setInqShow(true);
                        
                    }
                }
                setLoading(false);
            } catch (err) {
                console.error("Axios Error:", {
                    message: err.message,
                    code: err.code,
                    config: err.config,
                    stack: err.stack,
                });
            }
        }

        input && getList();

        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [input, inputKey]);
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const MySubmit = async () => {
            try {
                setLoading(true);
                const res = await axiosPrivate.post(`/add/mobile`, { input });
                alert("شماره موبایل وجود نداشت و به طور خودکار ذخیره شد")
                setLoading(false);
                
                console.log("Response from /add/mobile:", res);
                if (!res || !res.data) {
                    console.warn("Response data is missing.");
                }
            } catch (err) {
                console.error("Axios Error:", {
                    message: err.message,
                    code: err.code,
                    config: err.config,
                    stack: err.stack,
                });
                console.error( "Error in MySubmit:", err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        };
        if (inqData) {
            console.log("inqData:", inqData);
    
            if (inqData?.code === "300") {
                MySubmit();
            }
    
            if (inqData?.code === "200" && inqData?.data?.[0]) {
                //setAccData(inqData.data[0]);
            } else if (inqData?.code === "200") {
                console.warn("inqData.data[0] is undefined.");
            }
        }
        if (!inqData) {
            //console.warn("محل خیره شماره خالی شد.");
        } else if (!inqData?.data) {
           // console.warn("ورودی در سیستم وجود ندارد");
        }
        
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [input, inqData]);

    // if (loading) {
    //     return (
    //         <div className="flex justify-center items-center h-screen">
    //             <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
    //         </div>
    //     );
    // }

    return (
        <section className="inq-result">
            <div>
                <span className="inline-block place-content-center mr-4 text-blue-800 dark:text-blue-300 text-lg ml-2">
                    <FontAwesomeIcon icon={faClipboardQuestion} />
                </span>
                <h5 className="inline-flex items-center">سرویس هویت تلفنی</h5>
                <div>
                    <div className="mt-4">
                        <div className="lg:w-1/2 inq-holder">
                            <div className="mx-auto text-left">
                                <form onSubmit={handleSubmit} className="flex items-center justify-center">
                                    <label className="ml-2">ورود شماره:</label>
                                    <div className="inline-block" style={{ direction: "ltr" }}>
                                        <PhoneInput
                                        forceDialCode="true"
                                        hideDropdown={true}
                                            defaultCountry="ir"
                                            value={phoneData.phone}
                                            onChange={handlePhoneChange}
                                            showCountryFlag={true}
                                            showCountryCode={true}
                                            ref={inputRef}
                                            placeholder="9XXXXXXXX"
                                        />
                                    </div>
                                    <button
                                        type={phoneData.phone ? "submit" : "button"}
                                        onClick={!phoneData.phone ? dontHandle : undefined}
                                        className="py-2 px-4 h-10 subBut text-white hover:bg-yellow bg-light-green rounded mr-2 cursor-pointer items-center"
                                        disabled={loading || !phoneData.phone}
                                    >
                                        
                                        {loading ? 'در حال ارسال...' : 'ثبت'}
                                    </button>
                                </form>
                            </div>
                        </div>
                        <BulkInp />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InpInqtruecaller;
