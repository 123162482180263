import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;
export const isFeatureEnabled = process.env.REACT_APP_FEATURE_FLAG === "true";
if (isFeatureEnabled) {
    console.log("Full features enabled", BASE_URL);
} else {
    console.log("Limited features for customer", BASE_URL);
}

// Create an instance of axios
const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Create a private axios instance with dynamic token retrieval
export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    },
});

// Add an interceptor to inject the token dynamically into the request
axiosPrivate.interceptors.request.use(
    config => {
        const token = localStorage.getItem('access');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        console.log(config);
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Response interceptor for handling 401 errors and other responses
axiosPrivate.interceptors.response.use(
    response => {
        // Checking if the response status is 200
        if (response.status !== 200) {
            alert('خطای سرور. لطفاً چند ثانیه بعد تلاش کنید!');
        }
        return response;
    },
    error => {
        // Check for 401 errors and handle them
        if (error.response?.status === 401) {
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            alert('رمز عبور شما منقضی شد مجدد وارد شوید!')
            window.location.href = '/sign-in'; // هدایت به صفحه لاگین
        } else {
            // If response status is not 200, show an alert
            alert('مشکلی در ارتباط با سرور وجود دارد. لطفاً دوباره تلاش کنید!');
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;